.form-group-collapse-new[data-v-00d0a108] .el-collapse, .form-group-collapse-new[data-v-00d0a108] .el-collapse-item__wrap {
  border: none;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item__wrap {
  background: none;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item__content {
  padding-bottom: 0;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item:last-child {
  margin-bottom: 0;
}
.form-group-collapse-new[data-v-00d0a108] .btn-wrapper {
  position: absolute;
  right: 20px;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item__header {
  position: relative;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item__header .el-collapse-item__arrow {
  position: absolute;
  left: 2px;
  font-weight: bold;
  color: #2984f8;
  font-size: 12px;
  top: 11px;
}
.form-group-collapse-new[data-v-00d0a108] .el-collapse-item.is-disabled .el-collapse-item__header::after {
  content: "";
}
.form-group-collapse-new[data-v-00d0a108] .el-input__inner {
  color: #111;
}
.form-group-collapse-new[data-v-00d0a108] .el-input.is-disabled .el-input__inner {
  color: #333;
}
.form-group-collapse-spe[data-v-00d0a108] .el-collapse-item__header {
  border-top: none;
  line-height: normal;
  height: 32px;
  margin-bottom: 10px;
}
.form-group-collapse-spe[data-v-00d0a108] .el-collapse-item__header.is-active {
  border-bottom-color: #e2e4e8;
}
.form-group-collapse-spe[data-v-00d0a108] .el-collapse-item__header .header-icon {
  color: #2984f8;
  margin-right: 5px;
}
.form-group-collapse-spe[data-v-00d0a108] .el-collapse-item__header .group2-title {
  font-weight: bold;
  padding-left: 22px;
  font-size: 16px;
}
.form-group-collapse-spe[data-v-00d0a108] .el-collapse-item__header .group2-title .expend-wrap {
  width: 15px;
  height: 15px;
  border: 1px solid #2984f8;
  display: block;
  position: absolute;
  left: 1px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  top: 9px;
}
.form-group-collapse-two[data-v-00d0a108] .el-collapse-item__header {
  border-top: 1px dashed #e2e4e8;
  position: relative;
  border-bottom: none;
  line-height: normal;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
.form-group-collapse-two[data-v-00d0a108] .el-collapse-item__header .group2-title {
  background: #f9fbff;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
  color: #2984f8;
  position: absolute;
  bottom: 0;
  left: 15px;
}