[data-v-1d5ca70a] .el-table [class*="el-table__row--level"] .el-table__expand-icon {
  visibility: hidden;
}
.headerClass1 th[data-v-1d5ca70a] {
  background: #f5f7fa !important;
}
.headerClass1 th.el-table__cell[data-v-1d5ca70a] {
  background-color: #f5f7fa !important;
}
.tableColumn[data-v-1d5ca70a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.tableColumn .popover[data-v-1d5ca70a] {
  width: 20px !important;
}
.showLabel[data-v-1d5ca70a] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tips-wrapper[data-v-1d5ca70a] {
  padding: 0 15px;
}
.tips-wrapper li[data-v-1d5ca70a] {
  list-style: none;
  border-bottom: 1px solid #e2e3e7;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tips-wrapper li .label[data-v-1d5ca70a] {
  width: 85px;
  color: #9c9c9c;
}
.tips-wrapper li .content[data-v-1d5ca70a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #000000;
  margin: 0;
}