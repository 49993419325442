.link-header[data-v-36900731] {
  width: 100%;
  background: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.link-header ul[data-v-36900731] {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.link-header ul li[data-v-36900731] {
  float: left;
  padding: 8px 0 8px 70px;
  position: relative;
  cursor: pointer;
}
.link-header ul li span[data-v-36900731] {
  padding-left: 10px;
}
.link-header ul li.activeTop span[data-v-36900731] {
  color: #0087FB;
}
.link-header ul li.activeTop[data-v-36900731]::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  background: #0087FB;
  border-radius: 5px;
  top: 16px;
}
.link-header ul li[data-v-36900731]::after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  width: 40px;
  border-bottom: 1px dashed #ccc;
  height: 0;
  top: 18px;
}
.link-header ul li[data-v-36900731]:nth-of-type(1) {
  padding: 8px 0;
}