.content[data-v-8c9061ae] {
  background: #fff;
  padding: 20px;
}
.header[data-v-8c9061ae] {
  width: 100%;
  height: 50px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px 10px 0;
}
.btns[data-v-8c9061ae] {
  position: absolute;
  bottom: 0;
  height: 66px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-8c9061ae] .el-table .el-table__cell {
  padding: 12px 0;
}