.form-group[data-v-2aca3170] .avue-group__header {
  height: auto;
  line-height: normal;
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-group[data-v-2aca3170] .avue-group__header::after {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #e2e4e8;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.form-group[data-v-2aca3170] .avue-group__title {
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}
.form-group[data-v-2aca3170] .avue-group__icon {
  color: #2984f8;
}
.form-group[data-v-2aca3170] .el-collapse-item__header {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: none !important;
  margin-bottom: 10px !important;
}
.form-group[data-v-2aca3170] .avue--detail .avue-form__group.avue-form__group--flex {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
}
.form-group[data-v-2aca3170] .avue--detail .avue-form__group.avue-form__group--flex .el-form-item--mini.el-form-item {
  height: 100%;
}
.form-group[data-v-2aca3170] .avue--detail .avue-form__group.avue-form__group--flex .el-form-item--mini.el-form-item .el-form-item__label,
.form-group[data-v-2aca3170] .avue--detail .avue-form__group.avue-form__group--flex .el-form-item--mini.el-form-item .el-form-item__content {
  height: 100%;
}
.form-group[data-v-2aca3170] .avue--detail .el-row {
  border-top: none;
  border-left: none;
}
.form-group[data-v-2aca3170] .el-form-item__label {
  color: #333333;
}
.tips-wrapper[data-v-2aca3170] {
  padding: 0 15px;
}
.tips-wrapper li[data-v-2aca3170] {
  list-style: none;
  border-bottom: 1px solid #e2e3e7;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tips-wrapper li .label[data-v-2aca3170] {
  width: 85px;
  color: #9c9c9c;
}
.tips-wrapper li .content[data-v-2aca3170] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #000000;
  margin: 0;
}