.form-dashed-head[data-v-6751f530] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: auto;
}
.form-dashed-head .form-dashed-head__icon[data-v-6751f530] {
  margin-right: 8px;
  font-size: 20px;
  color: #2984f8;
}
.form-dashed-head .form-dashed-head__title[data-v-6751f530] {
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}
.form-dashed-head[data-v-6751f530]::after {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #e2e4e8;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}